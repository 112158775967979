import { ReactElement } from 'react'
import image1 from './assets/Slide1.JPG'
import image10 from './assets/Slide10.JPG'
import image11 from './assets/Slide11.JPG'
import image12 from './assets/Slide12.JPG'
import image13 from './assets/Slide13.JPG'
import image14 from './assets/Slide14.JPG'
import image15 from './assets/Slide15.JPG'
import image16 from './assets/Slide16.JPG'
import image17 from './assets/Slide17.JPG'
import image18 from './assets/Slide18.JPG'
import image19 from './assets/Slide19.JPG'
import image2 from './assets/Slide2.JPG'
import image20 from './assets/Slide20.JPG'
import image21 from './assets/Slide21.JPG'
import image22 from './assets/Slide22.JPG'
import image23 from './assets/Slide23.JPG'
import image24 from './assets/Slide24.JPG'
import image25 from './assets/Slide25.JPG'
import image26 from './assets/Slide26.JPG'
import image27 from './assets/Slide27.JPG'
import image28 from './assets/Slide28.JPG'
import image29 from './assets/Slide29.JPG'
import image3 from './assets/Slide3.JPG'
import image30 from './assets/Slide30.JPG'
import image31 from './assets/Slide31.JPG'
import image32 from './assets/Slide32.JPG'
import image33 from './assets/Slide33.JPG'
import image4 from './assets/Slide4.JPG'
import image5 from './assets/Slide5.JPG'
import image6 from './assets/Slide6.JPG'
import image7 from './assets/Slide7.JPG'
import image8 from './assets/Slide8.JPG'
import image9 from './assets/Slide9.JPG'
import * as S from './style'

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33]

export default function RecognitionWaveThree (): ReactElement {
  return <S.Container>
      {images.map((image) => <img src={image} alt="" />)}
    </S.Container>
}
