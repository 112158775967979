import styled from 'styled-components';

export const MessageContainer = styled.p`
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  iframe {
    aspect-ratio: 1920 / 1080;
    display: block;
    height: auto;
    width: 100%;
  }
`;
